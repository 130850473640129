function SecWelcomeContent({img}){
  return(
      <div className="welcome-content">
          <div className="promo-section">
              <div className="integration-link">
                  <span className="integration-icon">
                      <img src={img} width="24" height="24" alt="" />
                  </span>
                  <span className="integration-text">Real Time Trade Alerts</span>
                  {/* <span className="integration-text">Profitable Trading Strategies</span> */}
                  {/* <span className="integration-text">Trading, Investing, and Portfolio Managment</span> */}
              </div>
          </div>
          <h1>The Future of Trading and Investing</h1>
          <p>
              Unleash the power of algorithmic trading. Leverage AI and quantitative analysis to maximize returns.
          </p>
          {/* easily and consistently identify   high probability */}
          <div className="dream-btn-group">
              {/* need to use browser router here */}
              <a href="/signup" className="btn dream-btn mr-3">Get Started</a>
              <a href="/signup" className="btn dream-btn">Member Sign In</a>
          </div>
      </div>
  )
}

export default SecWelcomeContent