import {useEffect} from 'react'

// import {Logo} from '../../data/data-layout/data-Header.js';
import AlphaExpertLogo from '../../assets/img/alphaexpert_logo.png';

import {Addshrink , addActiveClass , OpenMenu , moveSmooth, moveSmoothAndCloseSidebar} from "../../utils/"

import './header.css'

import Preloader from '../../components/Preloader'

const Header = () => {

  useEffect(() => {
      Addshrink()
  },[])

  useEffect(() => {
      OpenMenu()
  },[])

  useEffect(() => {
      moveSmooth()
  },[])

  return (
    <>
      <Preloader />
      <header className="header-area wow fadeInDown" data-wow-delay="0.2s">
        <div className="classy-nav-container breakpoint-off">
          <div className="container">
            <nav className="classy-navbar justify-content-between" id="dreamNav">
              <a className="nav-brand" href="/">
                <img src={AlphaExpertLogo} style={{height: "50px"}} alt="logo" />
                </a>
              <div className="classy-navbar-toggler">
                <span className="navbarToggler" onClick={addActiveClass}>
                    <span />
                    <span />
                    <span />
                </span>
              </div>
              <div className="classy-menu" style={{overflowY:"auto"}}>
                <div className="classycloseIcon">
                  <div className="cross-wrap" onClick={addActiveClass}>
                      <span className="top" />
                      <span className="bottom" />
                  </div>
                </div>
                <div className="classynav">
                  <ul id="nav">
                    <li><a onClick={moveSmoothAndCloseSidebar} href="/">Home</a></li>
                    <li><a onClick={moveSmoothAndCloseSidebar} href="/#about">About</a></li>
                    <li><a onClick={moveSmoothAndCloseSidebar} href="/#performance">Performance</a></li>
                    <li><a onClick={moveSmoothAndCloseSidebar} href="/#services">Technologies</a></li>
                    <li><a href="/signup">JOIN</a></li>
                    {/* <li><a onClick={moveSmooth} href="/#contact">Contact</a></li> */}
                  </ul>
                  <a href="/signup" className="btn login-btn ml-50">Sign in</a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;