const SecIco = ({logo}) => {
	return(
        <div className="col-12 col-md-5">
          <div className="footer-copywrite-info mb-4">
            {/* Copywrite */}
            <div className="copywrite_text wow fadeInUp" data-wow-delay="0.2s">
              <div className="footer-logo">
                {/* <a href="#"><img src={logo} alt="logo" /></a> */}
                <a href="/"><img src={logo} style={{width:"275px"}} alt="logo" /></a>
              </div>
              <p>© {new Date().getFullYear()} AlphaExpert, LLC. All Rights Reserved.</p>
            </div>
            {/* Social Icon */}
            <div className="footer-social-info wow fadeInUp" data-wow-delay="0.4s">
              {/* <a href="#"><i className="fa fa-facebook" aria-hidden="true" /></a> */}
              <a href="https://twitter.com/alphaexpertio"> <i className="fa fa-twitter" aria-hidden="true" /></a>
              <a href="https://www.youtube.com/@alphaexpertio/"><i className="fa fa-youtube" aria-hidden="true" /></a>
              <a href="https://www.instagram.com/alphaexpert.io/"><i className="fa fa-instagram" aria-hidden="true" /></a>
              <a href="https://www.linkedin.com/company/alphaexpert"><i className="fa fa-linkedin" aria-hidden="true" /></a>
            </div>
          </div>
        </div>
	)
}

export default SecIco