import TermsOfServiceContainer from '../containers/TermsOfService'
import Header from '../layouts/Header';

const Home = () => {

  return (
    <>
      <Header />
      <TermsOfServiceContainer />
    </>
  );
}

export default Home;

