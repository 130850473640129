function SectionHeading(){
  return(
    <div className="section-heading section-padding-200-0 text-center">
        <div className="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s">
            {Array(7).fill().map((item , key) => (
                <span key={key}></span>
              ))}
        </div>
        <h2 className="wow fadeInUp" data-wow-delay="0.3s">Privacy Policy</h2>
        <p className="wow fadeInUp text-left mt-5" data-wow-delay="0.4s">
          Last Updated: August 28, 2024< br/>
          < br/>
          AlphaExpert, LLC ("we," "us," "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Software as a Service (SaaS) solution, website, and related services (collectively, the "Service"). By using the Service, you consent to the practices described in this Privacy Policy. If you do not agree with these terms, please do not use the Service.< br/>
          < br/>
          <strong>1. Information We Collect</strong>< br/>

          1.1. Personal Information:< br/>
          We may collect personally identifiable information that you provide to us, such as:
          Name,
          Email address,
          Phone number,
          Billing information (credit card details, billing address),
          Other information you provide directly to us.
          This information is collected when you register for the Service, subscribe to our newsletter, or contact us.< br/>

          1.2. Usage Data:< br/>
          We automatically collect information about how you access and use the Service ("Usage Data"). This may include: < br/>
          - IP Address: Your computer's Internet Protocol address.< br/>
          - Browser Information: Browser type and version.< br/>
          - Access Times: Date and time of your visit.< br/>
          - Device Information: Type of device and operating system used.< br/>
          - Usage Patterns: Pages viewed and actions taken within the Service.<br/>
          < br/>
          <strong>2. How We Use Your Information</strong>< br/>
          We use the collected information to:< br/>
          Provide and Improve the Service: To operate, maintain, and enhance the Service.
          Process Transactions: To handle billing and payment processing.
          Communicate with You: To send you updates, notifications, and customer support.
          Analyze Usage: To understand how users interact with the Service and improve user experience.
          Compliance: To comply with legal obligations and enforce our Terms of Service.< br/>
          < br/>
          <strong>3. Disclosure of Your Information</strong>< br/>
          We may disclose your information in the following circumstances:< br/>
          Service Providers: We may share your information with third-party service providers who assist us in operating the Service (e.g., payment processors, analytics providers).< br/>
          Legal Requirements: We may disclose information if required to do so by law or in response to valid legal requests (e.g., court orders, subpoenas).< br/>
          Business Transfers: In connection with a merger, acquisition, or sale of assets, where your information may be transferred as part of the business transaction.< br/>
          < br/>
          <strong>4. Security of Your Information</strong>< br/>
          We use reasonable measures to protect your information from unauthorized access, disclosure, or loss. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.< br/>
          < br/>
          <strong>5. Data Retention</strong>< br/>
          We retain your information for as long as necessary to fulfill the purposes described in this Privacy Policy, comply with legal obligations, and enforce our agreements.< br/>
          < br/>
          <strong>6. Your Choices and Rights</strong>< br/>

          6.1. Access and Correction:< br/>
          You may request access to and correction of your personal information by contacting us.< br/>

          6.2. Opt-Out:< br/>
          You may opt out of receiving promotional communications from us by following the unsubscribe instructions included in those communications or by contacting us directly.< br/>

          6.3. Data Deletion:< br/>
          You may request deletion of your personal information by contacting us. Please note that certain information may be retained as required by law or for legitimate business purposes.< br/>
          < br/>
          <strong>7. Cookies and Tracking Technologies</strong>< br/>
          We use cookies and similar tracking technologies to collect usage data and enhance your experience. You can set your browser to refuse cookies or to alert you when cookies are being sent. However, disabling cookies may affect your ability to use certain features of the Service.< br/>
          < br/>
          <strong>8. Third-Party Links</strong>< br/>
          Our Service may contain links to third-party websites. We are not responsible for the privacy practices or content of these third parties. We encourage you to review the privacy policies of any third-party sites you visit.< br/>
          < br/>
          <strong>9. Children’s Privacy</strong>< br/>
          Our Service is not intended for children under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected personal information from a child, we will take steps to delete that information.< br/>
          < br/>
          <strong>10. Changes to This Privacy Policy</strong>< br/>
          We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting on the Service. Your continued use of the Service after the posting of revised Privacy Policy constitutes your acceptance of the changes.
        </p>
        
    </div>
  )
}

export default SectionHeading