import {useEffect}  from "react";
import data from '../../data/data-containers/data-Home.js';

import {handelTitle} from '../../utils'

import './demo.css'

import Footer from '../../layouts/Footer'
// import {Fluid1 , Fluid2} from './Fluid';
import SectionHeading from './SectionHeading'

const HomeContainer = () => {

  useEffect(() => {
    handelTitle('AlphaExpert | Terms of Service')
  },[])

  useEffect(() => {
    if (document.title === 'AlphaExpert | Terms of Service') {
      document.getElementsByTagName("div")[0].style.backgroundImage = 'linear-gradient(180deg,#121212 8%,#0f0240 25%,#330747 45%,#0f0240 65%,#0f0240)'
    }else{
    	document.getElementsByTagName("div")[0].style.backgroundImage = 'linear-gradient(to right, #4834d4, #341f97)'
    }
  },[])

  return (
  	<>
      {/* make sure height takes up 100% vh */}
	  	<section className="demo" id="demo">
      {/* <section className="demo section-padding-100" id="demo"> */}
		    <div className="container">

		      <SectionHeading />
          
          {/* <p>Terms Of Services And General User Information</p>
          <p>
            AlphaExpert is not an investment advisor or securities broker. All information provided herein is made to subscribers, investors and speculators in general strictly for informational and educational purposes without regard to their individual investment goals, financial circumstances, investment knowledge and abilities or personal attitudes toward investing or trading. Investors can and do lose money from investments.
          </p> */}
          <div className="section-padding-100"></div>

		    </div>
	    </section>
	    <Footer />
    </>
  );
}

export default HomeContainer;

