import {
    HomeDemo1IcoPlatforms1,
    HomeDemo1IcoPlatforms2,
    HomeDemo1IcoPlatforms3,
    HomeDemo1IcoPlatforms4,
    HomeDemo1IcoPlatforms5,
    HomeDemo1IcoPlatforms6,

    HomeDemo1Services1,
    HomeDemo1Services2,
    HomeDemo1Services3,
    HomeDemo1Services4,
    HomeDemo1Services5,
    HomeDemo1Services6,

    HomeDemo1TokenIcon1,

    HomeDemo1TeamImg1,
    HomeDemo1TeamImg2,
    HomeDemo1TeamImg3,
    HomeDemo1TeamImg4,
    
    HomeDemo1Partners1,
    HomeDemo1Partners2,
    HomeDemo1Partners3,
    HomeDemo1Partners4,
    HomeDemo1Partners5,
    HomeDemo1Partners6,
    HomeDemo1Partners7,
    HomeDemo1Partners8,
    HomeDemo1Partners9,
    HomeDemo1Partners10,
    HomeDemo1Partners11,
    HomeDemo1Partners12,

    HomeDemo1BlogImg1,
    HomeDemo1BlogImg2,
    HomeDemo1BlogImg3

} from '../../utils/allImgs'

export const VerticalSocial = [
    {nameIco:'fa fa-telegram'},
    {nameIco:'fa fa-medium'},
    {nameIco:'fa fa-facebook'},
    {nameIco:'fa fa-twitter'},
    {nameIco:'fa fa-github'},
    {nameIco:'fa fa-instagram'},
    {nameIco:'fa fa-linkedin'}
]

export const SingleCoolFact = [
    {
        img:HomeDemo1IcoPlatforms1,
        ico_check:true
    },
    {
        img:HomeDemo1IcoPlatforms2,
        ico_check:false
    },
    {
        img:HomeDemo1IcoPlatforms3,
        ico_check:true
    },
    {
        img:HomeDemo1IcoPlatforms4,
        ico_check:true
    },
    {
        img:HomeDemo1IcoPlatforms5,
        ico_check:false
    },
    {
        img:HomeDemo1IcoPlatforms6,
        ico_check:true
    }
]

export const service_single_content = [
    {
        img:HomeDemo1Services1,
        title:'Artificial Intelligence',
        description:'Our state-of-the-art AI technology identifies the best trading opportunities, ensuring you stay ahead of the game.'
    },
    {
        img:HomeDemo1Services2,
        title:'Real Time Trade Alerts',
        description:'Receive real-time entry and exit trade notifications for names on your watchlist, so you never miss a trading opportunity.'
    },
    {
        img:HomeDemo1Services3,
        title:'Quantitative Analysis',
        description:'Leverage quantitative analysis to make data-driven decisions and identify high probability trading opportunities.'
    },
    {
        img:HomeDemo1Services4,
        title:'Backtest Profitability',
        description:'Test strategy performance with historical data to make informed decisions. Our risk analysis tools help you make the right decisions.'
    },
    {
        img:HomeDemo1Services5,
        title:'Statistical Analysis',
        description:'Get in-depth insights through statistical analysis of market trends. Make risk-aware decisions and protect your investments.'
    },
    {
        img:HomeDemo1Services6,
        title:'Data Science',
        description:'Harness the power of data science to gain a competitive edge. Understand the potential impact of each trade on your portfolio before you make a move.'
    }

]

export const timelineInfo = [
    {
        timelineClass:"block block-left",
        title:'Platform Development Starts',
        date_from:'May 12, 2018',
        date_to:'Jun 19, 2018'
    },
    {
        timelineClass:"block block-right mt-30",
        title:'Groundwork Preparation for ICO',
        date_from:'Aug 18, 2018',
        date_to:'Sep 23, 2018'
    },
    {
        timelineClass:"block block-left mt-30",
        title:'Crypto Blockchain Release',
        date_from:'Oct 08, 2018',
        date_to:'Nov 16, 2018'
    },
    {
        timelineClass:"block block-right mt-30",
        title:'Release Bank & Cards Phase',
        date_from:'Dec 28, 2018',
        date_to:'Jan 29, 2019'
    }
]

export const ServiceBlock = [
    {
        classIco:"icon ti-mobile",
        title:"Real-Time Trade Monitoring",
        description:"Our real-time trade monitoring ensures you're always in the loop. Whether you're at your desk or on the go, you'll have access to the latest updates."
    },
    {
        classIco:"icon ti-widget",
        title:"Performance Tracking",
        description:"Our platform offers detailed performance tracking for all of our trading strategies. You can see exactly how each strategy is performing in real time."
    },
    {
        classIco:"icon ti-settings",
        title:"Customizable Watchlist",
        description:"Tailor your watchlist to include the assets and securities that matter most to you. Get instant updates on the stocks, ETFs, or other instruments you're interested in."
    },
]

export const SocialListIco = [
    {icoName:"fa fa-facebook-square"},
    {icoName:"fa fa-twitter-square"},
    {icoName:"fa fa-github-square"},
    {icoName:"fa fa-instagram"},
    {icoName:"fa fa-medium"}
]

export const FQAInfo = [
    {
        text:"Is it for novice traders or professionals?",
        answer:"AlphaExpert caters to everyone, from novice traders starting with a few thousand dollars to professional money managers with multi-million dollar portfolios.",
        ID:"Q1"
    },
    {
        text:"How quickly do I need to act upon alerts?",
        answer:"You'll receive trade alerts with suggested entry prices. Typically, you have a few hours to act. If you miss a signal, wait for the next one.",
        ID:"Q2"
    },
    {
        text:"When do I take profits?",
        answer:"AlphaExpert monitors your open positions and alerts you when they reach profit-taking levels.",
        ID:"Q3"
    },
    {
        text:"What can I trade with AlphaExpert?",
        answer:"Our strategies primarily focus on opportunities in Stocks, Options, and ETFs.",
        ID:"Q4"
    },
    {
        text:"How much time does it take to manage trades?",
        answer:"Managing positions should not take more than 10-20 minutes per trading day.",
        ID:"Q5"
    },
    {
        text:"What do I need to get started?",
        answer:"You'll need a funded brokerage account with options trading enabled.",
        ID:"Q6"
    },
    {
        text:"How much capital do I need to get started?",
        answer:"You can start with a few hundred dollars, but we recommend beginning with at least $5,000 for sustainable growth and diversification.",
        ID:"Q7"
    },
    {
        text:"What types of strategies are available?",
        answer:"We offer multiple strategies to diversify your portfolio. Allocate your capital across various proven strategies, combining long and short positions with different timeframes for an optimal portfolio experience.",
        ID:"Q8"
    },
    {
        text:"What type of market is AlphaExpert good for?",
        answer:"Our strategies perform well in any market - bull, bear, or sideways trends.",
        ID:"Q9"
    },
    {
        text:"Are there any fees or profit splits?",
        answer:"The only cost is the membership fee. You retain full control of your portfolio and keep 100% of your returns.",
        ID:"Q10"
    },
    {
        text:"How much am I risking per trade?",
        answer:"Each strategy outlines its risk profile, with a fixed amount of risk defined before entering a trade. You'll know your maximum possible downside and profit potential before deciding on a trade.",
        ID:"Q11"
    },
    {
        text:"What if I need to cancel my membership?",
        answer:"There is no time commitment, you can cancel your membership anytime without any cost or fees.",
        ID:"Q12"
    },
]

export const DocElementTitle = [
    {
        title:'Trading Strategies Returns'
    },
    {
        title:'Investment Portfolio Returns'
    },
    // {
    //     title:'Stocks vs Our Strategies'
    // },
    // {
    //     title:'Alpha Added on Benchmarks'
    // },
]

export const TokenText = [
    {
        text:'Lorem ipsum dolor sit amet, conse ctetur elit',
        img:HomeDemo1TokenIcon1
    },
    {
        text:'Sed quis accumsan nisi Ut ut felis',
        img:HomeDemo1TokenIcon1
    },
    {
        text:'felis congue nisl hendrerit commodo',
        img:HomeDemo1TokenIcon1
    },
    {
        text:'arch nemo sequi rem saepe ad quasi ullam.',
        img:HomeDemo1TokenIcon1
    },
]

export const MissionData = [
    {
        icoName:"ti-shine",
        title:"Quality"
    },
    {
        icoName:"ti-ruler-pencil",
        title:"Creativity"
    },
    {
        icoName:"ti-heart",
        title:"Reliability"
    },
]

export const TeamMember = [
    {
        img:HomeDemo1TeamImg1,
        title:'Sunny khan',
        text:'Executive Officer'
    },
    {
        img:HomeDemo1TeamImg2,
        title:'Ajoy Das',
        text:'Business Development'
    },
    {
        img:HomeDemo1TeamImg3,
        title:'Afroza Mou',
        text:'UX/UI Designer'
    },
    {
        img:HomeDemo1TeamImg4,
        title:'Lim Sarah',
        text:'Head of Marketing'
    },
    {
        img:HomeDemo1TeamImg1,
        title:'Sunny khan',
        text:'Executive Officer'
    },
    {
        img:HomeDemo1TeamImg2,
        title:'Ajoy Das',
        text:'Business Development'
    },
    {
        img:HomeDemo1TeamImg3,
        title:'Afroza Mou',
        text:'UX/UI Designer'
    },
    {
        img:HomeDemo1TeamImg4,
        title:'Lim Sarah',
        text:'Head of Marketing'
    },
]

export const PartnersData = [
    {img:HomeDemo1Partners1},
    {img:HomeDemo1Partners2},
    {img:HomeDemo1Partners3},
    {img:HomeDemo1Partners4},
    {img:HomeDemo1Partners5},
    {img:HomeDemo1Partners6},
    {img:HomeDemo1Partners7},
    {img:HomeDemo1Partners8},
    {img:HomeDemo1Partners9},
    {img:HomeDemo1Partners10},
    {img:HomeDemo1Partners11},
    {img:HomeDemo1Partners12}
]

export const BlogPost = [
    {img:HomeDemo1BlogImg1},
    {img:HomeDemo1BlogImg2},
    {img:HomeDemo1BlogImg3}
]
