import React , {useEffect} from "react";
import {Helmet} from "react-helmet";
import { Switch, Route } from "react-router-dom";
import Aos from 'aos'

import Home from './pages/Home'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsOfService from './pages/TermsOfService'
import HomeDemo1 from './template/HomeDemo1'

import 'aos/dist/aos.css';
import './assets/css/General.css';
import './assets/css/bootstrap.min.css';
import './assets/css/responsive.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';

import ReactGA from 'react-ga4';

const App = () => {

  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_MEASUREMENT_ID}`)
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 1000
      })
  },[])

  return (
    	<div className="App">
        <Helmet>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <title>
            AlphaExpert | Unleash the Power of Algorithmic Trading
          </title>
          <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" integrity="sha256-eZrrJcwDc/3uDhsdt61sL2oOBY362qM3lon1gyExkL0=" crossorigin="anonymous" />
        </Helmet>
  			<Switch>
          <Route path="/signup" exact component={Home} />
          <Route path="/privacypolicy" exact component={PrivacyPolicy} />
          <Route path="/termsofservice" exact component={TermsOfService} />
  				<Route path="/" component={HomeDemo1} />
  			</Switch>
	    </div>
  );
}

export default App;