import {useEffect} from "react";
import { Line } from 'rc-progress';

const IcoCounter = () => {

  // useEffect(() => {
  //   let PathProgs = document.querySelector('.rc-progress-line-trail')
  //   PathProgs.setAttribute('stroke' , "transparent")
  // },[])

  return (
    <div className="ico-counter">
        <div className="counter-down">

            <div className="content">
                <div className="conuter-header">
                    <h2 className="text-center mb-15 text-white">Free 14-Day Trial</h2>
                </div>

                <h5 className="text-center text-white-50">Limited Time Offer</h5>

                {/* <div className="current-progress">
                  <Line percent="75" trailWidth="3" strokeWidth="3" strokeColor="#4834d4" />
                </div> */}

                <div className="conuter-header mt-3">
                    <h3 className="text-center">$199/month</h3>
                </div>
                <p className="text-center text-white-50">Cancel Anytime</p>
                <div className="text-center">
                    <a href="/signup" className="button mt-10">Sign Up Now</a>
                </div>

            </div>
            
        </div>
    </div>
  );
}

export default IcoCounter;