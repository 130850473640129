import SectionHeading from '../../../components/SectionHeading'
import Content from './Content'

const SecOurServices = ({data}) => {

  return (
      <section className="our_services_area clearfix section-padding-100-0" id="services">
        {/* section-padding-100-70 */}
        {/* section-padding-100-70*/}
        <div className="container">
            
            <SectionHeading
                title='How does it work?'
                text='Leveraging state-of-the-art technologies, we identify the best trading opportunities and provide you with real-time trading alerts.'
            />


            <div className="row">
                {data && data.map((item , key) => (
                    <Content
                        key={key}
                        img={item.img}
                        title={item.title}
                        description={item.description}
                    />
                ))}
            </div>
        </div>
    </section>
  );
}

export default SecOurServices;