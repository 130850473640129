import CountUp from 'react-countup';

const SecWelcomeMeter = ({img}) => {

  return (
    <div className="col-12 col-lg-6" data-aos="fade-up">
        <div className="welcome-meter">
            <img src={img} className="img-responsive center-block my-4" alt="" />
            {/* <div className="growing-company text-center mt-30">
                <p>Members have seen gains as high as <span className="counter"><CountUp start={0} end={230} duration={20}/> </span>% annual returns.</p>
            </div> */}
        </div>
    </div>
  );
}

export default SecWelcomeMeter;