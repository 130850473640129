const DocElement = ({title}) => {

  return (
    <div className="col-md-6 col-sm-6 col-xs-12" data-aos="fade-up">
      <a href="/signup">
        <div className="doc-element">
            <a className="document-entry" href="/signup" ><span className="title">{title}</span></a>
            {/* target="_blank" */}
        </div>
      </a>
    </div>
  );
}

export default DocElement;