const SecWelcomeMeter = ({img}) => {

  return (
    <div className="col-12 col-lg-7">
        <div className="welcome-meter" data-aos="fade-up">
            <img src={img} className="img-responsive center-block mt-5" alt="" />
        </div>
    </div>
  );
}

export default SecWelcomeMeter;