import React, {useEffect, useState} from 'react';
// import '../../template/HomeDemo1/style/InputFieldStyles.scss'
// import MailchimpSubscribe from 'react-mailchimp-subscribe';
import InputField from '../InputField';

const CustomForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    useEffect(() => {
        if(status === "success") clearFields();
    }, [status])

    const clearFields = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        firstName &&
        lastName &&
        email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
            MERGE1: firstName,
            MERGE2: lastName,
        });
    }

    return (
        <form className="" onSubmit={(e) => handleSubmit(e)}>
            {/* <h3 className="">Join our email list for future updates.</h3> */}

            {/* <h3 className="text-white">
                {status === "success" 
                    ? "Success!" 
                    : ""
                }
            </h3> */}



            <div className="d-flex justify-content-center col-lg-8 col-md-8 offset-md-2 col-xs-12 text-center section-padding-0-50">

                        {status !== "success" ? (
                            <div className="">
                                <InputField
                                    label="First Name"
                                    onChangeHandler={setFirstName}
                                    type="text"
                                    value={firstName}
                                    // placeholder="First Name"
                                    isRequired
                                />

                                <InputField
                                    label="Last Name"
                                    onChangeHandler={setLastName}
                                    type="text"
                                    value={lastName}
                                    // placeholder="Last Name"
                                    isRequired
                                />

                                <InputField
                                    label="Email"
                                    onChangeHandler={setEmail}
                                    type="email"
                                    value={email}
                                    // placeholder="Email"
                                    isRequired
                                />

                                <InputField
                                label="submit"
                                type="submit"
                                formValues={[email, firstName, lastName]}
                                />

                                {/* <button className="dream-btn col-1"><i className="fa fa-paper-plane-o"></i></button> */}
                                {/* <div className='d-flex justify-content-center'> */}
                                {/* </div> */}
                            </div>

                        ) : null}

            </div>


            <div className="d-flex justify-content-center col-lg-8 col-md-8 offset-md-2 col-xs-12 text-center section-padding-0-100">
                {/* error and sending messages */}
                {status === "sending" && (
                    <div className="text-white mt-4">
                        sending...
                    </div>
                )}
                {status === "error" && (
                    <div 
                        className="text-white mt-4"
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
                {status === "success" && (
                    <div
                        className="text-white"
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
            </div>


        </form>
    );
};

export default CustomForm;

