
const SingleFAQArea = ({text, answer, ID}) => {

  return (
  	<>
	    <dt className="wave" data-toggle="collapse" data-target={`#${ID}`}>{text}</dt>

		{/* data-aos="fade-up" */}
	    <dd id={ID} className="collapse">
			<p>{answer}</p>
	    </dd>
  	</>
  );
}

export default SingleFAQArea;