const Span = () => <span></span>

const SectionHeading = ({title , text}) => {

  return (
    <div className="section-heading text-center">
        <div className="dream-dots justify-content-center" data-aos="fade-up" data-aos-delay='200'>
            {Array(7).fill().map((item , key) => (
                    <Span key={key} />
                ))}
        </div>
        <h2 data-aos="fade-up" data-aos-delay='300'>{title}</h2>
        <p data-aos="fade-up" data-aos-delay='400'>{text}</p>
{/* 
        <h2 className="mt-4">Strategies for both:</h2>
        <p>• active tactile trading</p>
        <p>• strategies for long term investment</p>
        <p className="mt-2">Having multiple strategies with a variety of timeframes smooths your equity curve and builds wealth.</p> */}

    </div>
  );
}

export default SectionHeading;