import SectionHeading from '../../../components/SectionHeading'
import SecSingleCoolFact from './SecSingleCoolFact'

const SecTrust = ({data}) => {

  return (
    <section className="trust-section section-padding-100"  id="about">
    {/* <section className="trust-section section-padding-100"> */}
        <SectionHeading
            title='AlphaExpert Trading Revolution'
            text="We bring the strategies and technologies used by top hedge funds directly to you. With AlphaExpert, you'll gain the edge you need to succeed."
            // text='Leveling the playing field in financial markets. Strategies and technologies used by top hedge funds brought directly to you.'
            // Strategies for active tactile trading and strategies for long term investment. Having multiple strategies with a variety of timeframes smooths your equity curve and builds wealth. 
            // brings you a profitable traders edge in any market
            // How does it work?
            // AlphaExpert runs advanced algorithms around the clock monitoring your watchlist in real time and alerting you when high probability trading opportunities become available.
        />
        {/* <div className="container">
            <div className="row">
                <SecSingleCoolFact data={data}/>
            </div>
        </div> */}
    </section>
  );
}

export default SecTrust;