import SectionHeading from '../../../components/SectionHeading'
import TokenInfo from './TokenInfo'

import IcoDocs from '../SecFAQ_Timeline/IcoDocs';
import { DocElementTitle } from '../../../data/data-containers/data-HomeDemo1';

const SecDistribution = ({img , data}) => {

  return (
    <section className="section-padding-100-50" id="performance">
        {/* <section className="token-distribution"> */}
        <div className="container">
            
            <SectionHeading
                title='Performance'
                text="We bring complete transparency to the world of finance. With our proven strategies you can trade and invest with confidence like never before."
            />

            <div className="col-12 d-flex justify-content-center mb-5">
                <div className="col-lg-8 col-md-8 col-sm-12">
                    <IcoDocs data={DocElementTitle} />
                </div>
            </div>

            {/* <div className="col-lg-6 col-md-6 col-sm-12"> */}
                {/* <h2 className="text-center">Trading Strategies</h2> */}
                {/* <div className="token-allocation">
                    <img src={img} className="center-block" alt="" />
                </div> */}
            {/* </div> */}
            {/* <div className="col-lg-6 col-md-6 col-sm-12"> */}
                {/* <div className="token-info-wapper"></div> */}
                {/* <h2 className="text-center">Investment Portfolio</h2> */}
                {/* {data && data.map((item , key) => (
                    <TokenInfo
                        key={key}
                        text={item.text}
                        img={item.img}
                    />
                ))} */}
            {/* </div> */}
        </div>
        
    </section>
  );
}

export default SecDistribution;