function SectionHeading(){
  return(
    <div className="section-heading section-padding-200-0 text-center">
        <div className="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s">
            {Array(7).fill().map((item , key) => (
                <span key={key}></span>
              ))}
        </div>
        <h2 className="wow fadeInUp" data-wow-delay="0.3s">Join Waitlist</h2>
        <p className="wow fadeInUp" data-wow-delay="0.4s">
          Gain exclusive access to our trading strategies performance documents. Claim your spot now for early membership entry!
        </p>
    </div>
  )
}

export default SectionHeading