const ServiceBlock = ({classIco , title, description}) => {

  return (
    <div className="services-block-four">
        <div className="inner-box d-flex align-items-center">
            <div className="icon-box">
                <span className={classIco}></span>
            </div>
            {/* <h3><a href="#">{title}</a></h3> */}
            <div className="pt-sm-2 pt-md-1 pt-lg-1 pt-xl-3">
              <h3 className="text-white">{title}</h3>
              <div className="text">{description}</div>
            </div>
        </div>
    </div>
  );
}

export default ServiceBlock;