import {useEffect}  from "react";
import data from '../../data/data-containers/data-Home.js';

import {handelTitle} from '../../utils'
import SecSubscribe from "../../template/HomeDemo1/SecSubscribe/index.jsx";

import './demo.css'

// import Footer from '../../layouts/Footer'
import Footer from '../../layouts/Footer'
// import {Fluid1 , Fluid2} from './Fluid';
import SectionHeading from './SectionHeading'
// import SectionDemo from './SectionDemo'

const HomeContainer = () => {

  useEffect(() => {
    handelTitle('AlphaExpert | Waitlist')
  },[])

  useEffect(() => {
    if (document.title === 'AlphaExpert | Waitlist') {
    	// document.getElementsByTagName("div")[0].style.backgroundImage = 'linear-gradient(180deg,#240044 0,#0f0240 25%,#400959 40%,#0f0240 65%,#0f0240)'
      document.getElementsByTagName("div")[0].style.backgroundImage = 'linear-gradient(180deg,#121212 8%,#0f0240 25%,#330747 45%,#0f0240 65%,#0f0240)'
    }else{
    	document.getElementsByTagName("div")[0].style.backgroundImage = 'linear-gradient(to right, #4834d4, #341f97)'
    }
  },[])

  return (
  	<>
      {/* make sure height takes up 100% vh */}
	  	<section className="demo" id="demo">
      {/* <section className="demo section-padding-100" id="demo"> */}
		    <div className="container">

		      <SectionHeading />
          <SecSubscribe className="section-padding-100-0"/>
		      {/* <SectionDemo data={data} /> */}

		    </div>
	    </section>
	    <Footer />
    </>
  );
}

export default HomeContainer;

