const SecContent = () => {
	return(
        <div className="col-12 col-md-7 mb-4">
          {/* Content Info */}
          <div className="contact_info_area d-sm-flex justify-content-between ml-4 pl-3">
            <div className="contact_info text-center wow fadeInUp mt-4" data-wow-delay="0.2s">
              <h5>NAVIGATE</h5>
              <a href="/"><p>Home</p></a>
              <a href="/#about"><p>About</p></a>
              <a href="/#performance"><p>Performance</p></a>
              <a href="/#services"><p>Technologies</p></a>
              {/* <a href="/"><p>Careers</p></a> */}
              {/* <a href="/#contact"><p>Contact</p></a> */}
            </div>
            {/* Content Info */}
            <div className="contact_info text-center wow fadeInUp mt-4" data-wow-delay="0.3s">
              <h5>PRIVACY &amp; TOS</h5>
              <a href="/privacypolicy"><p>Privacy Policy</p></a>
              <a href="/termsofservice"><p>Terms of Service</p></a>
            </div>
            {/* Content Info */}
            <div className="contact_info text-center wow fadeInUp mt-4" data-wow-delay="0.4s">
              <h5>Contact</h5>
              <p>info@alphaexpert.io</p>
            </div>
          </div>
        </div>
	)
}

export default SecContent