import SectionHeading from '../../../components/SectionHeading'
import ServiceBlock from './ServiceBlock'

const SecOurFeatures = ({data , imgPhone , Rings}) => {

  return (
    <section className="features section-padding-100-50 mt-5">
    {/* <section className="features section-padding-100"> */}

        <SectionHeading
            title='A Platform for Everyone'
            // text='Our User-Friendly dashboard makes it easy for novice traders to get started. Seasoned money managers have access to advanced tools to enhance their portfolios.'
            // text='🖥️ User-Friendly Interface: Our intuitive dashboard is designed for novice traders, making it easy to get started. 💼 Premium Features: For seasoned money managers, we offer a suite of advanced tools to enhance your trading experience. Seamlessly integrate your watchlist with your portfolio.'
        />

        <div className="container">
            <div className="row">
                <div className="col-12 mb-5">
                    <div className="col-lg-1 col-md-0 col-sm-0"></div>
                    <div className="col-lg-5 col-md-6 col-sm-12" data-aos="fade-up">
                        <h5 className="text-center text-white mb-3">Novice Traders</h5>
                        <p className="mb-5">Our user-friendly dashboard makes it easy for novice traders to get started, using historical data and AI to provide the highest probable returns.</p>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-12" data-aos="fade-up">
                        <h5 className="text-center text-white mb-3">Professionals</h5>
                        <p className="mb-5">For seasoned money managers we offer a suite of advanced tools to enhance your trading experience. Seamlessly integrate your watchlist with your portfolio.</p>
                    <div className="col-lg-1 col-md-0 col-sm-0"></div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid">
            <div className="row">
                <div className="services-column col-lg-6 col-lg-offset-3 col-md-8 offset-md-2 col-xs-10 offset-xs-1" data-aos="fade-up">
                    {/* <div className="services-column col-lg-5 col-lg-offset-1 col-md-10 offset-md-1 col-xs-10 offset-xs-1"> */}
                    {data && data.map((item , key, description) => (
                        <ServiceBlock
                            key={key}
                            classIco={item.classIco}
                            title={item.title}
                            description={item.description}
                        />
                    ))}
                </div>
                {/* <div className="service-img-wrapper col-lg-6 col-md-12 col-sm-12">
                    <div className="image-box">
                        <img src={imgPhone} className="center-block img-responsive phone-img" alt="" />
                        <img src={Rings} className="center-block img-responsive rings " alt="" />
                    </div>
                </div> */}
            </div>
        </div>
    </section>
  );
}

export default SecOurFeatures;