const Span = () => <span></span>

const SecWhoWeContant = () => {

  return (
    <div className="col-12 col-lg-5 mt-4">
        <div className="who-we-contant">
            <div className="dream-dots" data-aos="fade-up">
                {Array(7).fill().map((key) => (
                		<Span />
                	))}
            </div>

            <h4 data-aos="fade-up">Investors add alpha to any investment portfolio</h4>
            
            {/* <p data-aos="fade-up"><strong>🔧 Build Your Portfolio</strong></p> */}
            <h6 data-aos="fade-up" className="text-white pt-2">Build Your Portfolio</h6>
            <p data-aos="fade-up" className="pl-2">Investors can construct a custom investment portfolio tailored to their unique goals. Take control of your financial future and watch your wealth grow.</p>
            
            {/* <p data-aos="fade-up"><strong>🔄 Backtest with Confidence</strong></p> */}
            <h6 data-aos="fade-up" className="text-white pt-2">Backtest with Confidence</h6>
            <p data-aos="fade-up" className="pl-2">Wondering how your portfolio will perform in different scenarios? Our portfolio construction tool lets you backtest your investments, giving you the confidence to make informed decisions.</p>
            
            {/* <p data-aos="fade-up"><strong>🚀 Enhanced Returns</strong></p> */}
            <h6 data-aos="fade-up" className="text-white pt-2">Enhanced Returns</h6>
            <p data-aos="fade-up" className="pl-2">We don't stop at portfolio construction. AlphaExpert also brings you strategies that work around your existing portfolio positions, further boosting your returns on long-term investments.</p>


            {/* <p data-aos="fade-up">Build a custom investment portfolio and backtest its performance with our portfolio construction tool.</p> */}
            {/* <p data-aos="fade-up">We also bring you strategies that run around your investment portfolio positions, further increasing returns on long term positions.</p> */}
            {/* with backtests showing how much alpha AlphaExpert adds to your portfolio's returns */}
            {/* <p data-aos="fade-up">Image showing PnL curve of AlphaExpert + growth portfolio, growth portfolio, QQQ, SPY, top hedge funds.</p> */}
            <a className="btn dream-btn mt-30" href="/signup">Get AlphaExpert Now</a>
        </div>
    </div>
  );
}

export default SecWhoWeContant;