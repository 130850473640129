import{
  // FooterLogo,
  FooterPattern,
  FooterBg1
} from '../../utils/allImgs'

import FooterLogo from '../../assets/img/alphaexpert_logo.png'
import './footer.css'

import SecIco from './SecIco'
import SecContent from './SecContent'

var Footer = () => {
    return (
      <footer className="footer-area bg-img" style={{backgroundImage: `url(${FooterPattern})`}}>
      {/* <footer className="footer-area bg-img" style={{backgroundColor: "#121212"}}> */}
        {/* <div className="footer-content-area " style={{backgroundImage: `url(${FooterBg1})`}}> */}
        <div className="footer-content-area " style={{backgroundColor: "#121212"}}>
        {/* <div className="footer-content-area " style={{backgroundColor: "#281b87"}}> */}
          <div className="container">
            <div className="row align-items-end">
              <SecIco logo={FooterLogo} />
              <SecContent />
            </div>
          </div>
        </div>
      </footer>
      // <>
      //   <div className="clearfix" />
      //   <footer className="footer-area bg-img" style={{backgroundImage: `url(${FooterPattern})`}}>
      //     <div className="footer-content-area " style={{backgroundImage: `url(${FooterBg1})`}}>
      //       <div className="container">
      //         <div className="row align-items-end">
      //           <SecIco logo={FooterLogo} />
      //           <SecContent />
      //         </div>
      //       </div>
      //     </div>
      //   </footer>
      // </>
    );
};

export default Footer