import HomeContainer from '../containers/Home'
import Header from '../layouts/Header';

const Home = () => {

  return (
    <>
      <Header />
      <HomeContainer />
    </>
  );
}

export default Home;

