const Span = () => <span></span>

const SecWhoWeContant = () => {

  return (
    <div className="col-12 col-lg-6">
        <div className="who-we-contant">
            <div className="dream-dots">
                {Array(7).fill().map((key) => (
                		<Span />
                	))}
            </div>

            <h4 data-aos="fade-up">Traders generate consistent returns with our trading strategies</h4>

            {/* <p data-aos="fade-up"><strong>📊 Consistent Returns</strong></p> */}
            <h6 data-aos="fade-up" className="text-white pt-2">Consistent Returns</h6>
            <p data-aos="fade-up" className="pl-2">Our proven trading strategies empower traders to generate consistent returns, regardless of market conditions. Whether it's a bull market, bear market, or sideways trend, we've got you covered.</p>
            
            {/* <p data-aos="fade-up"><strong>📈 Transparent Trading</strong></p> */}
            <h6 data-aos="fade-up" className="text-white pt-2">Transparent Trading</h6>
            <p data-aos="fade-up" className="pl-2">We're here to bring complete transparency to the world of finance. No more guesswork or hidden agendas. See the performance of our strategies for yourself, and watch your investments grow.</p>
            {/* <p data-aos="fade-up">Bringing complete transparency to the world of finance.</p> */}
            {/* <p data-aos="fade-up">Strategies that perform in bull, bear, and sideways markets.</p> */}
            {/* <p data-aos="fade-up">Backtest any stock to see strategy trade performance.</p> */}
            {/* <p data-aos="fade-up">See our proven strategies performance documents.</p> */}
            <a data-aos="fade-up" className="btn dream-btn mt-30" href="/signup">Free Trial</a>
        </div>
    </div>
  );
}

export default SecWhoWeContant;