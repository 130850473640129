function SectionHeading(){
  return(
    <div className="section-heading section-padding-200-0 text-center">
        <div className="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s">
            {Array(7).fill().map((item , key) => (
                <span key={key}></span>
              ))}
        </div>
        <h2 className="wow fadeInUp" data-wow-delay="0.3s">Terms of Service</h2>
        <p className="wow fadeInUp text-left mt-5" data-wow-delay="0.4s">
          Last Updated: August 28, 2024< br/>
          < br/>
          Welcome to AlphaExpert, LLC ("we," "us," "our"). These Terms of Service ("Terms") govern your use of our platform, Software as a Service (SaaS) solution, website, and any related services (collectively, the "Service"). By accessing or using our Service, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Service.< br/>
          < br/>
          <strong>1. Eligibility</strong>< br/>
          You must be at least 18 years old to use our Service. By using our Service, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms.< br/>
          < br/>          
          <strong>2. Acceptance of Terms</strong>< br/>
          By accessing or using our Software as a Service (SaaS) solution, you agree to be bound by these terms and conditions. If you do not agree to all the Terms, you may not access or use the Service.< br/>
          < br/>
          <strong>3. Description of Service</strong>< br/>
          The Service is a cloud-based platform offering trading signals generated by algorithmic trading strategies and market analysis tools for financial markets. It is available on a subscription basis, and you agree to pay the fees specified in the pricing plan.< br/>
          < br/>
          <strong>4. Use of Service</strong>< br/>
          
          4.1. License:< br/>
          We grant you a limited, non-exclusive, non-transferable, revocable license to access and use our Service for personal and non-commercial purposes, subject to these Terms.< br/>
          
          4.2. Restrictions:< br/>
          You agree not to:< br/>
          - Use the Service for any unlawful purpose.< br/>
          - Reproduce, distribute, modify, or create derivative works from the Service.< br/>
          - Interfere with the security or integrity of the Service.< br/>
          - Use the Service to disseminate any malicious code or harmful content.< br/>
          < br/>
          <strong>5. Account Registration</strong>< br/>
          To access certain features of the Service, you may be required to create an account. You agree to provide accurate and complete information during registration and to update your information as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.< br/>
          < br/>
          <strong>6. Trading Signals</strong>< br/>
          
          6.1. No Investment Advice:< br/>
          The Service provides trading signals based on algorithms and market analysis. These signals are for informational purposes only and do not constitute investment advice. We do not guarantee the accuracy, completeness, or reliability of the signals.< br/>
          
          6.2. Risk Disclosure:< br/>
          Trading in financial markets involves significant risk. You acknowledge and agree that you are solely responsible for any decisions you make based on the signals and information provided by the Service or otherwise. You also acknowledge and agree that you are responsible for all your actions, even those not directly related to the specific signals or information provided by the Service.< br/>
          < br/>
          <strong>7. Fees and Payments</strong>< br/>
          
          7.1. Subscription: < br/>
          Access to certain features of the Service may require a subscription. You agree to pay all applicable fees for the chosen subscription plan.< br/>
          
          7.2. Billing:< br/>
          Payment for the Service is processed through third-party payment processors. You agree to provide accurate billing information and authorize us to charge your payment method for the subscription fees.< br/>
          
          7.3. Cancellation and Refunds:< br/>
          If you choose to cancel your subscription, you must do so through your account settings or by contacting our support team. Cancellations will take effect at the end of the current billing period. Please note that no refunds will be issued for the current billing period or any previous payments, and you will continue to have access to the Service until the end of the billing cycle for which you have paid.< br/>
          < br/>
          <strong>8. Termination</strong>< br/>
          We reserve the right to suspend or terminate your access to the Service at any time, with or without notice, for any reason, including but not limited to a breach of these Terms. Upon termination, your right to use the Service will immediately cease.< br/>
          < br/>
          <strong>9. Intellectual Property</strong>< br/>
          All intellectual property rights in the Service, including but not limited to trademarks, copyrights, and patents, are owned by us or our licensors. You agree not to infringe upon any of our intellectual property rights.< br/>
          < br/>
          <strong>10. Data Privacy</strong>< br/>
          We will collect and use your data in accordance with our Privacy Policy. Please review our Privacy Policy for details on how we handle your personal information.< br/>
          < br/>
          <strong>11. Disclaimer and Limitation of Liability</strong>< br/>
          
          11.1. Disclaimer of Warranties:< br/>
          The Service is provided "as is" and "as available" without warranties of any kind, whether express or implied. To the fullest extent permitted by law, we disclaim all warranties, express or implied, including but not limited to merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that the Service will be uninterrupted, secure, or error-free, nor do we make any warranty as to the accuracy, reliability, or completeness of any information provided through the Service.< br/>
          
          11.2. Limitation of Liability:< br/>
          Under no circumstances shall AlphaExpert, LLC, its affiliates, directors, officers, employees, or agents be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of capital, data, use, goodwill, or other intangible losses, resulting from:< br/>
          - Your use of or inability to use the Service.< br/>
          - Any conduct or content of any third party on the Service.< br/>
          - Any content obtained from the Service.< br/>
          - Unauthorized access, use, or alteration of your transmissions or content.< br/>
          - Any inaccuracies or omissions in the content provided.< br/>
          - Any other matter related or unrelated to the Service.< br/>
          
          11.3. No Liability for Third Parties:< br/>
          We are not responsible for the actions, content, or data of third parties, and you release us, our directors, officers, employees, and agents from any claims and damages, known and unknown, arising out of or in any way connected with any claim you have against any such third parties.< br/>
          < br/>
          <strong>12. Indemnification</strong>< br/>
          You agree to indemnify, defend, and hold harmless AlphaExpert, LLC, its affiliates, directors, officers, employees, and agents from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable legal fees and costs, arising out of or in any way connected with:< br/>
          - Your access to or use of the Service.< br/>
          - Your violation of these Terms.< br/>
          - Your infringement of any intellectual property or other rights of any third party.< br/>
          - Any claim that your use of the Service caused damage to a third party.< br/>
          - Any other claim related or unrelated to the Service.< br/>
          < br/>
          <strong>13. Governing Law</strong>< br/>
          These Terms shall be governed by and construed in accordance with the laws of Washington, without regard to its conflict of law principles. Any disputes arising under these Terms shall be resolved in the courts located in Washington.< br/>
          < br/>
          <strong>14. Entire Agreement</strong>< br/>
          These Terms constitute the entire agreement between you and AlphaExpert, LLC concerning the Service and supersede all prior agreements and understandings, whether written or oral, related to the Service. Any additional terms and conditions issued by us shall be deemed to be incorporated into these Terms unless expressly stated otherwise.< br/>
          < br/>
          <strong>15. Changes to Terms</strong>< br/>
          We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on the Service. Your continued use of the Service after the posting of revised Terms constitutes your acceptance of the changes.< br/>
        </p>

    </div>
  )
}

export default SectionHeading