import {useEffect}  from "react";
import data from '../../data/data-containers/data-Home.js';

import {handelTitle} from '../../utils'

import './demo.css'

import Footer from '../../layouts/Footer'
// import {Fluid1 , Fluid2} from './Fluid';
import SectionHeading from './SectionHeading'

const HomeContainer = () => {

  useEffect(() => {
    handelTitle('AlphaExpert | Privacy Policy')
  },[])

  useEffect(() => {
    if (document.title === 'AlphaExpert | Privacy Policy') {
      document.getElementsByTagName("div")[0].style.backgroundImage = 'linear-gradient(180deg,#121212 8%,#0f0240 25%,#330747 45%,#0f0240 65%,#0f0240)'
    }else{
    	document.getElementsByTagName("div")[0].style.backgroundImage = 'linear-gradient(to right, #4834d4, #341f97)'
    }
  },[])

  return (
  	<>
      {/* make sure height takes up 100% vh */}
	  	<section className="demo" id="demo">
      {/* <section className="demo section-padding-100" id="demo"> */}
		    <div className="container">

		      <SectionHeading />

          {/* <p>PRIVACY & POLICY</p>
          <p>
            The following privacy policy (“Policy”) governs the information practices used on alphaexpert.io (the “Website”) for the services provided to you as set forth in the Terms of Use for the Website. Throughout this Policy, we refer to information that personally identifies you as “personal information”. All capitalized terms not otherwise defined in this Policy shall be as set forth in the Terms of Service for the website.
          </p> */}
          <div className="section-padding-100"></div>

		    </div>
	    </section>
	    <Footer />
    </>
  );
}

export default HomeContainer;

