import React from 'react';
// import '../../template/HomeDemo1/style/inputFieldStyles.scss';

const InputField = props => {

    const validateInput = values => {
        if (values.some(f => f === "") || values[0].indexOf("@") === -1) {
            return true
        } else {
            return false
        }
    }

    if (props.type === "submit") {
        return (
            <div className='col-8 mb-4'>
                {/* <button className="dream-btn col-3"> */}
                {/* <i className="fa fa-paper-plane-o"></i> */}
                {/* need to import CSS for button and lines */}
                <input
                    className='dream-btn col-6 mt-4 mr-1'
                    type='submit'
                    value={props.label}
                    disabled={validateInput(props.formValues)}
                />

                {/* </button> */}
            </div>
        )
    } else if (props.type === "textarea") {
        return (
            <div className='col-12'>
                <label className="text-white">
                    {props.label}
                    <textarea
                        onChange={(e) => props.onChangeHandler(e.target.value)}
                        placeholder={props.placeholder}
                        value={props.value}
                        required={props.isRequired}
                        className="col-12 mt-2"
                        rows={7}
                        name={props.name}
                        rows="8"
                    />
                </label>
            </div>
        );
    } else {
        return (
            <div className='col-12 mb-4'>
                <label className="text-white col-12 text-left">
                    {props.label}
                </label>
                <input
                    onChange={(e) => props.onChangeHandler(e.target.value)}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    required={props.isRequired}
                    className="col-11"
                    name={props.name}
                />
            </div>
        );
    }


};

export default React.memo(InputField);